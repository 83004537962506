// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before, .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.3137254902);
  animation: prixClipFix 2s linear infinite;
}

.loader::after {
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #FF3D00;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%, 100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Common/SpinnerComponent/SpinnerComponent.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,oCAAA;AACF;;AACA;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,6CAAA;EACA,yCAAA;AAEF;;AAAA;EACE,UAAA;EACA,sCAAA;EACA,qBAAA;AAGF;;AAAA;EACE;IAAM,uBAAA;EAIN;EAHA;IAAQ,yBAAA;EAMR;AACF;AAJA;EACI;IAAM,oDAAA;EAOR;EANE;IAAM,gEAAA;EASR;EARE;IAAY,yEAAA;EAWd;AACF","sourcesContent":[".loader {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  position: relative;\n  animation: rotate 1s linear infinite\n}\n.loader::before , .loader::after {\n  content: \"\";\n  box-sizing: border-box;\n  position: absolute;\n  inset: 0px;\n  border-radius: 50%;\n  border: 5px solid #00000050;\n  animation: prixClipFix 2s linear infinite ;\n}\n.loader::after{\n  inset: 8px;\n  transform: rotate3d(90, 90, 0, 180deg );\n  border-color: #FF3D00;\n}\n\n@keyframes rotate {\n  0%   {transform: rotate(0deg)}\n  100%   {transform: rotate(360deg)}\n}\n\n@keyframes prixClipFix {\n    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}\n    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}\n    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
