import { capitalizeFirstLetter } from '../utils/utilFunction'
import api from './axios'

const path = '/api/v1'

export const getContactDetails = async (id) => await api.post(`${path}/get-contact-details`, { contact_id: +id })
// TODO to test api
export const markImportStatus = async (body) => await api.post(`${path}/select-import-status`, body)
export const markContacted = async (body) => await api.post(`${path}/mark-contacted`, body)

// auth APIs
export const orgInfoApi = async () => await api.get(`${path}/org-domain/`)
export const getReferralInfo = async (value) => await api.post(`${path}/auth/referrer-info/`, { referral_code: value })
export const sendOtpRequest = async (body) => await api.post(`${path}/auth/send-otp/`, body)
export const signUp = async (body) => {
  const data = {
    ...body,
    first_name: capitalizeFirstLetter(body.first_name),
    last_name: capitalizeFirstLetter(body.last_name)
  }
  return await api.post(`${path}/auth/signup/`, data)
}
export const verifyOtp = async (body) => await api.post(`${path}/auth/login/`, body)

// upgrade friend or connection to ambassador
export const upgradeUserApi = async (code) => await api.post(`${path}/auth/me/upgrade/`, { referral_code: code })

// profile section
export const userInfo = async () => await api.get(`${path}/auth/me/profile/`)
export const getReferralUrl = async () => await api.get(`${path}/auth/me/referral-info/`)
export const updateProfile = async (body) => await api.patch(`${path}/auth/me/profile/`, body, {
  headers: { 'Content-Type': 'multipart/form-data;' }
})
export const deleteAccountApi = async () => await api.delete(`${path}/auth/me/delete/`)
export const updateAddress = async (body) => await api.put(`${path}/auth/me/profile/address/`, body)
export const userPointsInfo = async () => await api.get(`${path}/auth/points/`)
export const userLevelsInfo = async () => await api.get(`${path}/org-levels/`)
export const communicationInfo = async () => await api.get(`${path}/auth/communication-preferences/`)
export const updateCommunicationInfo = async (body) => await api.put(`${path}/auth/communication-preferences/`, body)
export const userPointSummary = async (page_size, page) => await api.get(
  `${path}/transactions/${page_size ? '?page_size=' + page_size : ''}${page ? '&page=' + page : ''
  }`
)
export const getLeaderBoard = async (limitedData) => await api.get(`${path}/leaderboard/${limitedData ? '?days=30' : ''}`)

// stripe and tango
export const StripWebhook = async () => await api.post(`${path}/stripe-webhook/`)
export const getClientSecret = async () => await api.post(`${path}/identity/verify/`)

// challenge APIs
export const listChallenge = async () => await api.get(`${path}/org-challenges/`)
export const getChallengeDetails = async (id) => await api.get(`${path}/org-challenges/${id}/`)
export const getChallengeData = async (id) => await api.get(`${path}/org-challenges/${id}/data/`)
export const updateChallengeStatus = async (id) => await api.post(`${path}/org-challenges/${id}/accept/`)
export const saveSharedChallenge = async (id, body) => await api.post(`${path}/org-challenges/${id}/complete/`, body)
export const getChallengeHistory = async ({
  id,
  status,
  page_size,
  page,
  search
}) => await api.get(
  `${path}/org-challenges/${id}/history/${page_size ? '?page_size=' + page_size : ''
  }${page ? '&page=' + page : ''}${status || status === 0 ? '&status=' + status : ''
  }${search ? '&search=' + search : ''}`
)
export const getChallengeMyList = async ({
  id,
  page_size,
  page,
  search,
  status
}) => await api.get(
  `${path}/org-challenges/${id}/friends/${page_size ? '?page_size=' + page_size : ''
  }${page ? '&page=' + page : ''}${status ? '&status=' + status : ''}${search ? '&search=' + search : ''}`
)
export const getShareChallengeUrl = async (id) => await api.get(`${path}/org-challenges/${id}/share/`)
export const getShareChallengeDetails = async (id, referrer_id) => await api.get(`${path}/org-challenges/${id}/${referrer_id}/intro/`)

// friend detail page followed by challenge
export const getFriendDetails = async (id) => await api.get(`${path}/${id}/friend-details/`)

// org admin's api
export const getOrgAdminHome = async ({
  page,
  pageSize,
  search,
  ordering,
  user_type,
  isDeleted
}) => await api.get(
  `${path}/org-admin/users/?${page ? 'page=' + page : ''}${pageSize ? '&page_size=' + pageSize : ''
  }${search ? '&search=' + search : ''}${user_type ? '&user_type=' + user_type : ''
  }${ordering ? '&ordering=' + ordering : ''
  }${isDeleted === false ? "&is_deleted=false" : isDeleted === true ? "&is_deleted=true" : ""
  }`
)
export const adminApprovalApi = async (id) => await api.post(`${path}/org-admin/user-challenges/${id}/complete/`)
export const deleteUser = async (id) => await api.delete(`${path}/org-admin/users/${id}/delete/`)
export const changeUserStatus = async (id, value) => await api.put(`${path}/org-admin/users/${id}/`, { is_active: value })
export const getPointsValue = async (isDeleted) => await api.get(`${path}/org-admin/org-balance/${isDeleted === false ? "?is_deleted=false" : isDeleted === true ? "?is_deleted=true" : ""
  }`)
export const upgradeAmbassadorToOrgAdminApi = async (id) => await api.put(`${path}/org-admin/users/${id}/upgrade/`)

// redeem
export const getRedemptionOptionsApi = async () => await api.get(`${path}/org-admin/org-redemptions/`)
export const addUserRedemption = async (id, redemption_id) => await api.post(
  `${path}/org-admin/users/${id}/org-redemptions/${redemption_id}/`
)
export const removeUserRedemption = async (id, redemption_id) => await api.delete(
  `${path}/org-admin/users/${id}/org-redemptions/${redemption_id}/`
)

//report
export const getChallengeReport = async (id) => await api.get(`${path}/org-admin/challenge-responses/${id}/export/`)
export const getCurrentViewReport = async (queryParams) => await api.get(`${path}/org-admin/current-view/export/?${queryParams}`)
export const getTransactionReport = async () => await api.get(`${path}/org-admin/transactions/export/`)
export const getUsersReport = async () => await api.get(`${path}/org-admin/users/export/`)

//redemption
export const donateBack = async (value) => await api.post(`${path}/redeem-donation/`, { points: value })
export const redeemTango = async (value) => await api.post(`${path}/redeem-tango/`, { points: value })
export const getRedemptionOptions = async () => await api.get(`${path}/redemption-options/`)
export const getMerchandiseApi = async () => await api.get(`${path}/merchandise/`)
export const updateShippingInfo = async (data) => await api.post(`${path}/redeem-merchandise/`, data)

// contacts
export const updateGoogleContacts = async (data) => await api.post(`${path}/contacts/upload/google/`, data)
export const getContacts = async ({ page, page_size = 20, is_exist, search }) => await api.get(
  `${path}/contacts/?page_size=${page_size}&page=${page}${!is_exist ? '' : is_exist === 'yes' ? '&is_exist=true' : '&is_exist=false'
  }${search ? '&search=' + search : ''}`
)
export const getContactVoters = async ({ page, page_size = 20, search }) => await api.get(
  `${path}/contacts/voters/?page_size=${page_size}&page=${page}${search ? '&search=' + search : ''}`
)
export const mapContact = async (id, postData) => await api.post(
  `${path}/contacts/${id}/voter/`, postData
)
export const deleteContactsApi = async () => await api.delete(`${path}/contacts/delete/`)
export const updateContactStatusApi = async ({ id, status, reason }) => await api.post(`${path}/contacts/${id}/update-status/${status}/`, {
  reason: reason
})
export const checkContactUploadStatus = async () => await api.get(`${path}/contacts/upload/check-progress/`)

// challenge based contact work
export const getShareContactDetails = async (id) => await api.get(`${path}/contacts/${id}/share/`)
export const getChallengeContacts = async ({
  id,
  page,
  page_size = 20,
  status,
  search
}) => await api.get(
  `${path}/contacts/challenges/${id}/?page_size=${page_size}&page=${page}${status || status === 0 ? '&status=' + status : ''
  }${search ? '&search=' + search : ''}`
)
export const updateChallengeContactStatus = async ({ id, status, reason }) => await api.post(`${path}/contacts/challenges/${id}/update-status/${status}/`, {
  reason: reason
})

// Election
export const getElectionDetails = async () => await api.get(`${path}/election-detail/`)
export const getPollingPlaces = async () => await api.get(`${path}/polling-places/`)
