import { lazy, Suspense } from "react";
import OpenRoute from "./OpenRoute";
import ProtectedRoute from "./ProtectedRoute";
import SpinnerComponent from "../components/Common/SpinnerComponent/SpinnerComponent";

/**
 * Lazily load the mentioned component which resides in the page directory
 * This method will be used in routes so that the files are loaded only
 * When users are on that route
 */
export function lazyLoadRoutes({
  componentName,
  isProtected,
  open,
  otherData,
}) {
  const LazyElement = lazy(() => import(`../pages/${componentName}/index.jsx`));

  // Wrapping around the suspense component is mandatory
  return (
    <Suspense
      fallback={
        <div className="center-content" style={{ height: "100%" }}>
          <SpinnerComponent />
        </div>
      }
    >
      {isProtected ? (
        <ProtectedRoute>
          <LazyElement {...otherData} />
        </ProtectedRoute>
      ) : (
        <>
          {open ? (
            <LazyElement {...otherData} />
          ) : (
            <OpenRoute>
              <LazyElement {...otherData} />
            </OpenRoute>
          )}
        </>
      )}
    </Suspense>
  );
}
